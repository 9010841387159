import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const role = route.data['role'] as string | undefined;
    let user = this.authService.user;
    if (!user && route.queryParams['token']) {
      await this.authService.check();
      user = this.authService.user;
    }
    if (user) {
      if (role && role !== user.role) {
        this.router.navigate(['/', 'client']);
        return false;
      }
      return true;
    }
    this.router.navigate(['/', 'auth'], {
      queryParams: {
        redirect: state.url,
      },
    });
    return false;
  }
}
